import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Form,
  Media,
  Label,
} from 'reactstrap';
import { forgotDataPassword } from '../../actions/user';
import Loading from '../utils/loading';
import Model from '../../model/forgot';
import FORGOT from '../../const/forgot';

class Recover extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.state = { ...Model, Redirected: false, Width: window.innerWidth };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { forgotPassword, history } = nextProps;
    const { Redirected } = nextState;

    if (forgotPassword && forgotPassword.state === false && Redirected) {
      if (forgotPassword && forgotPassword.success === true) {
        history.push({
          pathname: '/alterar-senha',
          state: nextState,
        });
      }
    }

    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    this.setState({ Width: window.innerWidth });
  }

  handleSubmit(event) {
    event.preventDefault();

    const p = this.props;
    const { Email } = this.state;

    p.forgotDataPassword(Email);
    this.setState({ Redirected: true });
  }

  render() {
    const { forgotPassword } = this.props;
    const { Email, Width } = this.state;

    if (forgotPassword && forgotPassword.state !== false) {
      return <Loading msg={FORGOT.LOADING} />;
    }

    const { match } = this.props;
    const { invalidToken } = match.params;
    const isMobile = Width < 999;

    return (
      <div className="app back-logo flex-row align-items-center">
        <Container>
          <Form onSubmit={this.handleSubmit}>
            <Row className="justify-content-center">
              <Col md="6">
                <CardGroup>
                  <Card className="p-4 border-top">
                    <CardBody>
                      <Row>
                        <Col xs={isMobile ? 6 : 5} className={`d-flex justify-content-end ${isMobile ? '' : 'p-0'}`}>
                          <Media
                            className="logo"
                            object
                            src="/assets/imgs/mapfre_logo.png"
                            alt="Logo"
                            style={{ width: '150px', height: '20px' }}
                          />
                        </Col>
                        <Col xs={isMobile ? 6 : 7} className="d-flex flex-column justify-content-center p-0">
                          <span className="title">{FORGOT.TITLE}</span>
                          <span className="sub-title">{FORGOT.SUB_TITLE}</span>
                        </Col>
                      </Row>
                      <Row className="justify-content-center text-center">
                        <Col md="12">
                          {
                            invalidToken
                              ? <h1 style={{ color: '#ee4250' }}>{FORGOT.FORGOT_INVALID_TEXT}</h1>
                              : <h1>{FORGOT.FORGOT_TEXT}</h1>
                          }
                        </Col>
                      </Row>
                      <hr />
                      <Row className="justify-content-center text-center">
                        <Col md="12 mb-3">
                          <Label style={{ fontSize: '18px' }}>{FORGOT.FORGOT_SUB_TEXT}</Label>
                        </Col>
                      </Row>
                      <InputGroup className="mb-5">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Email"
                          required="required"
                          id="Email"
                          name="Email"
                          value={Email}
                          maxLength="50"
                          onChange={(e) => this.setState({ Email: e.target.value })}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="6" className="float-left">
                          <Button color="primary" className="px-4 m-0 bg-p2b border-0" id="ConfirmButton" name="ConfirmButton" style={{ width: '100%' }}>
                            {FORGOT.CONFIRM_BUTTON}
                          </Button>
                        </Col>
                        <Col xs="6" className="float-left">
                          <a href="/#/login" className="px-4 m-0 btn btn-secondary" id="CancelButton" name="CancelButton" style={{ width: '100%' }}>
                            {FORGOT.CANCEL_BUTTON}
                          </a>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}

Recover.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.bool }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      invalidToken: PropTypes.bool,
    }),
  }).isRequired,
  forgotDataPassword: PropTypes.func.isRequired,
  forgotPassword: PropTypes.shape({
    data: PropTypes.shape({}),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    forgotDataPassword,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  forgotPassword: state.userState.forgotPassword,
});

export default connect(mapStateToProps, mapDispatchToProps)(Recover);
