export default {
  LOADING: 'Carregando...',
  TITLE: 'Bem vindo ao',
  SUB_TITLE: 'Portal Mapfre',
  FORGOT: 'Login',
  FORGOT_TEXT: 'Esqueci minha senha',
  FORGOT_INVALID_TEXT: 'Seu prazo para criar uma nova senha expirou',
  FORGOT_SUB_TEXT: 'Informe seu e-mail para receber um link de configuração de senha',
  CONFIRM_BUTTON: 'Confirmar',
  CANCEL_BUTTON: 'Cancelar',
  FORGOT_MSG: 'Para recuperar sua senha, entre com contato com o Administrador do Sistema.',
};
