import React from 'react';
import Logo from '../assets/imgs/mapfre_logo.png';

function DefaultFooter() {
  return (
    <div className="w-100 d-flex justify-content-center">
      <img src={Logo} alt="Logo" width={75} />
    </div>
  );
}

export default DefaultFooter;
